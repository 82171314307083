import { Newsletter } from "./Newsletter";

export const MailchimpForm = () => {

  return (
    <>
      <Newsletter />
    </>
  )
}
